import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth }from '../../Context/auth'

import { clientService } from '../../Services/clientService';
import { businessService } from "../../Services/businessService";
import { businessProductService } from '../../Services/businessProductService';
import { boromirService} from '../../Services/Boromir/boromirService';
import { boromirUserService } from '../../Services/Boromir/boromirUserService';
import { notificationService } from '../../Services/notificationsService'

import swal from 'sweetalert';

import { PhoneInput,
    defaultCountries,
    parseCountry 
} from 'react-international-phone';

import 'react-international-phone/style.css';

const PullApartIndex = () => {
    const auth = useAuth();
    const { id } = useParams();
    const navigate =  useNavigate();   
    
    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['us', 'mx'].includes(iso2);
    });

    const [stateBusiness, setstateBusiness] = useState({});

    const [inputClientIdValue, setInputClientIdValue] = useState('');
    //const [inputClientValue, setInputClientValue] = useState(''); 
    const [filteredSuggestions, setFilteredSuggestions] = useState([]); 
    const [useStateClients, setUsestateClients] = useState([]);

    const [stateBusinessProduct, setStateBusinessProduct] = useState([]);
    const [inputProdValue, setInputProdValue] = useState('');
    const [inputProdSelectedValue, setInputProdSelectedValue] = useState({});
    const [filteredProds, setFilteredProds] = useState([]); 

    const [statenumberItems, setstatenumberItems] = useState(0);

    const [stateDD, useStateDD] = useState('');
    const [stateMM, useStateMM] = useState('');
    const [stateYY, useStateYY] = useState('');

    // const [stateEmail, setstateEmail] = useState('');
    // const [stateAddress, setstateAddress] = useState('');

    const [hiddenSpinner1, sethiddenSpinner1] = useState(false);

    const [stateEnableBtnSave, setstateEnableBtnSave] = useState('A');

    const [stateSale, setstateSale] = useState({        
        companyId: 0,
		businessId: 0,
        businessName: '',
        paymentGateWayId: 0,
		saleDate: new Date,
        clientName: '',
        clientAddress: '',
        email: '',
        productId: 0,
        productName: '',
        ammount: 0,
		ammountPre: 0,
        cxcNumber: 0,		
		details: '',
        description: '',
        phone: ''
    });

    useEffect(() => {
        clientService.getAllBycompanyId(auth.user.token, auth.user.companyId).then((response) => {
            setUsestateClients(response.data);
        });

        businessService.getById(auth.user.token, id).then(response => {
            setstateBusiness(response.data);
        });

        businessProductService.getByBusinessId(auth.user.token, id).then(response => {            
            setStateBusinessProduct(response.data);
        });    

        var today = new Date();
        useStateDD(String(today.getDate()).padStart(2, '0'));
        useStateMM(String(today.getMonth() + 1).padStart(2, '0')); //January is 0!
        useStateYY(today.getFullYear());        
        
    }, []);

    const onChangehandleChange = (event, pField) => {
        const {name, value} = event.target;
        setstateSale((prevState) => ({
            ...prevState,
            [name]: value
        }));

        switch(pField){
            case 'clientName':                
                setFilteredSuggestions(getFilteredSuggestions(value));
                break;           
            case 'Product':                      
                setFilteredProds(getFilteredProds(value));            
                break;            
        }              
    }   

    // Function to filter suggestions based on user input
    const  getFilteredSuggestions = (inputValue) => {
        return useStateClients.filter(item => item.name.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    const  getFilteredProds = (inputValue) => {
        return stateBusinessProduct.filter(item => item.name.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    const handleSuggestionClick = (suggestion) => {
        setInputClientIdValue(suggestion.id);
        setstateSale((prevState) => ({
            ...prevState,
            ['clientName']: suggestion.name
        })); 
        setstateSale((prevState) => ({
            ...prevState,
            ['clientAddress']: 'NA'
        }));
        setstateSale((prevState) => ({
            ...prevState,
            ['email']: suggestion.email
        })); 
        setFilteredSuggestions([]); // Clear suggestions after selection
    }

    const handleProdClick = (prod) => {       
        setstateSale((prevState) => ({
            ...prevState,
            ['productId']: prod.id
        }));  
        setstateSale((prevState) => ({
            ...prevState,
            ['productName']: prod.name
        })); 
        setFilteredProds([]); // Clear suggestions after selection
    }
  
    const onclickBtnCancel = (e) => {
        e.defaulPrevent;

        navigate(`/PullApartSelStore`, { replace: true });
    }

    const onBlur_inputEmail = (e) => { 
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(e.target.value)) {
            swal("Correo invalido!", "el formato de correo no es valido!", "error")
            .then((value) => {
                setstateSale((prevState) => ({
                    ...prevState,
                    ['email']: ''
                })); 
            });   
        }  
    }

    const onclickbtnSave = async (e) => {
        e.preventDefault;     

        setstateEnableBtnSave('');

        stateSale.companyId = auth.user.companyId;
        stateSale.businessId = stateBusiness.id;
        stateSale.businessName = stateBusiness.businessName;
        stateSale.paymentGateWayId = stateBusiness.paymentGatewayId;
       
        const isvalid = Validatedata();

        //console.log(stateSale);

        if(isvalid)
        {
            sethiddenSpinner1(true);

            const userForAuthenticate = {
                "User": "soporte@axify.mx",
                "Password": "Axify2023"
            };
            const responseUserBoromir = await boromirUserService.postAuthenticate(userForAuthenticate);
            const tokentoboromir = responseUserBoromir.token;            

            let responseBoromir = await boromirService.postSaleWithOutRef(tokentoboromir, stateSale);

            sethiddenSpinner1(false);

            if (responseBoromir.data.id > 0 ) {

                var responseNotification = await notificationService.postSendEmailByNewSale({ "SaleId" : responseBoromir.data.id });

                swal("Se realizo el apartado con exito!", 'Folio de apartado = ' + responseBoromir.data.id, "success")
                .then((value) => {                    
                    setstateSale(
                        {        
                            companyId: 0,
                            businessId: 0,
                            businessName: '',
                            paymentGateWayId: 0,
                            saleDate: new Date,
                            clientName: '',
                            clientAddress: '',
                            email: '',
                            productId: 0,
                            productName: '',
                            ammount: 0,
                            ammountPre: 0,
                            cxcNumber: 0,		
                            details: '',
                            description: '',
                            phone: ''
                        }
                    );
                    setstateEnableBtnSave('A');
                });
            } else {
                swal("NO se realizo el apartado con exito!", 'Favor de notificar al administrador de sistema', "error")
                .then((value) => {
                    setstateSale(
                        {        
                            companyId: 0,
                            businessId: 0,
                            businessName: '',
                            paymentGateWayId: 0,
                            saleDate: new Date,
                            clientName: '',
                            clientAddress: '',
                            email: '',
                            productId: 0,
                            productName: '',
                            ammount: 0,
                            ammountPre: 0,
                            cxcNumber: 0,		
                            details: '',
                            description: '',
                            phone: ''
                        }
                    );
                    setstateEnableBtnSave('A');
                });            
            }
        } else {
            swal("datos incompletos!", 'Favor de llenar todos los datos necesarios para realizar el apartado', "error");            
        }
    }

    const onhandleChangePhone = (event) => {                
        setstateSale({...stateSale, phone: event});
    };

    const Validatedata  = () => {
        let validate = true;
    
        validate = validate && (stateSale.companyId > 0 ? true : false);
        validate = validate && (stateSale.businessId > 0 ? true : false);
        validate = validate && (stateSale.productId > 0 ? true : false);
        validate = validate && (stateSale.email.length > 0 ? true : false);
        validate = validate && (stateSale.clientAddress.length > 0 ? true : false);
        validate = validate && (+stateSale.ammount > 0 ? true : false);
        validate = validate && (+stateSale.ammountPre > 0 ? true : false);
        validate = validate && (+stateSale.cxcNumber > 0 ? true : false);
        validate = validate && (stateSale.phone.length > 0 ? true : false);
    
        return validate;
      }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div className="row">
                            <div className="col">
                                <h4>Registro de apartado</h4>
                                <h6>{stateBusiness.businessName}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="row">
                            <div className="col">                   
                                <input className="form-control" placeholder="Dia" disabled value={stateDD}/>
                            </div>
                            <div className="col">
                                <input className="form-control" placeholder="Mes" disabled value={stateMM}/>
                            </div>
                            <div className="col">
                                <input className="form-control" placeholder="Año" disabled value={stateYY}/>
                            </div>                            
                        </div>
                        <div className="row">
                            <div className="col">
                                <input className="form-control" placeholder="No." disabled/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">                        
                        <input type="hidden" className="form-control" placeholder="id" value={inputClientIdValue} />
                        <div class="form-group row">                 
                            <label for="clientName" class="col-sm-2 col-form-label">Sr. Sra.</label>
                            <div class="col-sm-10">
                                <input type="text" className="form-control" placeholder="Sr. Sra." 
                                    name='clientName'
                                    id='clientName'
                                    value={stateSale.clientName} 
                                    onChange={e => onChangehandleChange(e, 'clientName')} />
                                <ul className="list-group" style={{position:"absolute", zIndex: "1"}}>
                                    {filteredSuggestions.map((suggestion, index) => (
                                        <li className="list-group-item list-group-item-primary" key={index} onClick={() => handleSuggestionClick(suggestion)}>{suggestion.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">                        
                        <div class="form-group row">
                            <label for="clientAddress" class="col-sm-2 col-form-label">Dirección</label>
                            <div class="col-sm-10">
                                <input className="form-control" placeholder="Dirección"
                                    id="clientAddress"
                                    name="clientAddress"
                                    type="text"
                                    value={stateSale.clientAddress}
                                    onChange={e => onChangehandleChange(e, '')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div class="form-group row">
                            <label for="email" class="col-sm-2 col-form-label">Email</label>
                            <div class="col-sm-10">
                                <input className="form-control" placeholder="Email"
                                    id="email"
                                    name="email"
                                    type="text"
                                    value={stateSale.email}
                                    onChange={e => onChangehandleChange(e, '')}
                                    onBlur={e => onBlur_inputEmail(e)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div class="form-group row">
                            <label for="phone" class="col-sm-2 col-form-label">Tel. Celular</label>
                            <div class="col-sm-10">
                                <PhoneInput 
                                    inputStyle={{width:'100%'}}                                          
                                    initialCountry="mx"                                                        
                                    countries={countries}             
                                    value={stateSale.phone}                                           
                                    onChange={(phone) => onhandleChangePhone(phone)}                                                        
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">                  
                    <div className="col">
                        <label for="productName">Producto</label>
                        <input type="text" className="form-control" placeholder="Producto" 
                            id="productName"
                            name="productName"                            
                            value={stateSale.productName} 
                            onChange={e => onChangehandleChange(e, 'Product')} />
                        <ul className="list-group"  style={{position:"absolute", zIndex: "1"}}>
                            {filteredProds.map((prod, index) => (
                                <li className="list-group-item list-group-item-primary" key={index} value={prod.id} onClick={() => handleProdClick(prod)}>{prod.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="col">
                        <label for="ammount">Monto total</label>
                        <input className="form-control" placeholder="Monto total"
                            id="ammount"
                            name='ammount'
                            value={stateSale.ammount}         
                            onChange={e => onChangehandleChange(e)} 
                        />
                    </div>
                    <div className="col">
                        <label for="ammountPre">Apartado</label>
                        <input className="form-control" placeholder="Apartado"
                            id="ammountPre"
                            name='ammountPre'
                            value={stateSale.ammountPre}         
                            onChange={e => onChangehandleChange(e)} 
                        />
                    </div>
                    <div className="col">
                        <label for="cxcNumber"># abonos</label>
                        <input className="form-control" placeholder="# abonos"
                            id="cxcNumber"
                            name='cxcNumber'
                            value={stateSale.cxcNumber}         
                            onChange={e => onChangehandleChange(e)}                        
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label for="details">Detalle</label>
                        <textarea className="form-control" rows={5} placeholder="Detalle"
                            id="details"
                            name="details"
                            type="text"
                            value={stateSale.details}
                            onChange={e => onChangehandleChange(e)}
                        />
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <button type="button" className="btn btn-primary" 
                        onClick={e => onclickbtnSave(e)}
                        disabled={!stateEnableBtnSave}>
                        Grabar
                    </button>
                    </div>
                    <div className="col">
                        { hiddenSpinner1 &&
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-primary" onClick={e => onclickBtnCancel(e)}>Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PullApartIndex;